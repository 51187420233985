import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Captainbiz = () => {
  const headingbanner = {
    title: `Logo Yazilim`,
    content: `A GST Billing Software`,
  };

  const data = {
    images: ["captain-biz.webp", "captain-biz-2.webp"],
    firstAlt: "UI UX dashboard to check the invoice status",
    firstTitle: "Invoice status dashboard",
    secondAlt: "UI Screen to create invoice",
    secondTitle: "Create Invoice",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["FinTech"],
      },
      {
        title: "What we did",
        text: ["UX Consultation", "UI UX Designing", "UI Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The platform was built to offer reliable and organised invoicing management for businesses to run efficiently and effectively.`,
          `However, the platform has several issues- `,
        ],
        list: [
          `Lacked user-friendliness and easy navigability`,
          `Comprehensive reporting features`,
          `Monitoring real-time inventory status`,
          `Multi-platform accessibility`
        ]
      },
      {
        title: "Solution",
        content: [
          `We offered a comprehensive solution to solve their existing problems and also offered upgraded features. `,
          `In order to solve the usability issue, we simplified their user flow and understood the user requirements to improve the functionality. `,
          `With our upgraded offerings, we enhanced the user experience and also made sure to provide space to add future improvements. `
        ],
      },
    ],
    image: ["captainbiz-problem.webp"],
  };

  const projectData = {
    title: `Invoices`,
    para: [
      `We created this dashboard for better user understanding as it provides the overall status of the invoices to the users to keep track of their payments.`,
    ],
    content: {
      image: ["captainbiz-projectone.webp"],
    },
  };

  const projectData2 = {
    title: `View Invoice`,
    para: [
      `This dashboard shows the overview of the invoice once it has been made and shared with the clients. We also created an option to edit the invoice in case of any errors in order to offer an exceptional user experience. `,
    ],
    content: {
      image: ["captainbiz-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `Manage Payment`,
    para: [
      `Users can also manage and set reminders to make the payment while adding notes and supporting documents which resulted in improved productivity. `,
    ],
    content: {
      image: [
        "captainbiz-projectthree.webp",
        "captainbiz-projectthree-2.webp",
        "captainbiz-projectthree-3.webp",
        "captainbiz-projectthree-4.webp",
        "captainbiz-projectthree-5.webp",
        "captainbiz-projectthree-6.webp",
        "captainbiz-projectthree-7.webp",
        "captainbiz-projectthree-8.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `The aim of this platform is to assist businesses in the smooth filing of GST invoices. However, with the growing market opportunities, the platform lacked certain features that can assist businesses to run effectively and efficiently. `,
        `With our solutions, we improved the user flow and the functionality of the website to improve the user experience and overall business process. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={25} />
      </div>
    </Layout>
  );
};

export default Captainbiz;

export const Head = () => (
  <Seo title="Logo Yazilim- GST Billing Software Case Study| Octet" description="We simplified a GST billing software by offfering our services such as UX Consultation, UI UX Designing and UI Development for smooth GST filing procedure." />
)